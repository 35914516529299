import getEnv from "sholdi-shared/lib/env/getEnv";
import PropTypes from "prop-types";

const prefixUrl = (url, appUrl, cdnUrl) => {
  if ((url && url.startsWith("http")) || !url) return url;
  const isImage = !!cdnUrl;
  const sufix = isImage ? `?image=${url}&w=1080&h=0&q=75` : "";
  const hostUrl = cdnUrl || appUrl;
  const urlWithSlash = url.startsWith("/") ? url : `/${url}`;
  return `${hostUrl}${urlWithSlash}${sufix}`;
};
const Metadata = ({
  metaDescription,
  metaTitle,
  image,
  url,
  canonical,
  type = "website",
  price,
  priceCurrency = "BAM",
}) => {
  const cdnUrl = getEnv("NEXT_PUBLIC_SHOLDI_IMAGE_CDN_URL");
  const appUrl = getEnv("NEXT_PUBLIC_APP_URL");
  const siteName = getEnv("NEXT_PUBLIC_SHOLDI_SITE_NAME");

  const urlWithPrefix = prefixUrl(url, appUrl);
  const canonicalUrl = prefixUrl(canonical, appUrl) || urlWithPrefix;
  const imageWithPrefix = prefixUrl(image, appUrl, cdnUrl);
  const metaTitleWithSiteName = `${metaTitle} | ${siteName}`;

  return (
    <>
      <title>{metaTitleWithSiteName}</title>
      <link rel="canonical" href={canonicalUrl} />
      <meta name="description" content={metaDescription} />
      <meta name="title" content={metaTitleWithSiteName} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={imageWithPrefix} />
      <meta property="og:url" content={urlWithPrefix} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content={siteName} />
      {price && <meta property="product:price:amount" content={price} />}
      {price && priceCurrency && (
        <meta property="product:price:currency" content={priceCurrency} />
      )}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@sholdi" />
      <meta name="twitter:creator" content="@sholdi" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={imageWithPrefix} />
      <meta name="twitter:image:alt" content={metaTitle} />
      <meta name="twitter:domain" content="sholdi.com" />
      <meta name="twitter:url" content={urlWithPrefix} />
    </>
  );
};

Metadata.propTypes = {
  metaDescription: PropTypes.string,
  metaTitle: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  canonical: PropTypes.string,
  type: PropTypes.oneOf(["website", "og:product", "article"]),
  category: PropTypes.string,
  price: PropTypes.string,
  priceCurrency: PropTypes.string,
};

export default Metadata;
