import { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

import Box from "../Box";

import variants from "./variants";

// Define Tailwind-based variants for ButtonBase
const buttonBaseClass = cva("", {
  variants: {
    variant: variants, // assuming 'variants' is an object with appropriate Tailwind classes
    direction: {
      rtl: "flex-row",
      ltr: "flex-row-reverse",
      rtlxxl: "flex-row",
      ltrxxl: "flex-row-reverse",
    },
    buttonSize: {
      none: "",
      chip: "py-1 px-2.5",
      xs: "text-xs py-1 px-2 leading-6",
      sm: "text-sm py-2 px-3.5",
      md: "text-sm py-2.5 px-4",
      lg: "text-base py-2.5 px-4.5 leading-6",
      xl: "text-base py-3 px-5 leading-6",
      xxl: "text-lg py-4 px-7 leading-7",
    },
  },
  defaultVariants: {
    direction: "ltr",
    buttonSize: "md",
    variant: "primary",
  },
});

const Button = forwardRef(
  (
    {
      children,
      icon: Icon,
      iconColor,
      iconProps,
      variant,
      direction,
      buttonSize,
      disabled,
      className,
      hideChildren,
      ...props
    },
    ref,
  ) => (
    <Box
      as="button"
      ref={ref}
      disabled={disabled}
      className={twMerge(
        "inline-flex gap-3 items-center justify-center cursor-pointer font-normal leading-6 whitespace-nowrap outline-none rounded text-white",
        className,
        buttonBaseClass({
          variant,
          direction,
          buttonSize,
        }),
        disabled ? "cursor-not-allowed" : "cursor-pointer",
      )}
      {...props}
    >
      <>
        {!hideChildren && children}
        {Icon && (
          <Icon
            color={iconColor}
            fill="transparent"
            className={twMerge(
              "vertical-align-middle mb-0.5",
              iconProps?.className,
            )}
            {...iconProps}
          />
        )}
      </>
    </Box>
  ),
);

Button.propTypes = {
  direction: PropTypes.oneOf(["rtl", "ltr"]),
  iconColor: PropTypes.string,
  iconProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  buttonSize: PropTypes.oneOf(["none", "xs", "sm", "md", "lg", "xl", "xxl"]),
  icon: PropTypes.elementType,
  children: PropTypes.node,
  variant: PropTypes.oneOf(Object.keys(variants)),
  hideChildren: PropTypes.bool,
};

Button.displayName = "Button";

export default memo(Button);
