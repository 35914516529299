import { useCallback, useEffect } from "react";
import { keyCodes } from "sholdi-shared/lib/constants/keyCodes";

const useDisableDocumentScroll = (hide, preventTabbing = false) => {
  const preventTabbingFunc = useCallback((e) => {
    if (e.keyCode === keyCodes.TAB) {
      e.preventDefault();
    }
  }, []);

  const setOverflow = useCallback((value) => {
    document.body.style.overflow = value;
  }, []);

  const toggleEventListener = useCallback(
    (add) => {
      const method = add ? "addEventListener" : "removeEventListener";
      document.body[method]("keydown", preventTabbingFunc, { passive: false });
    },
    [preventTabbingFunc],
  );

  useEffect(() => {
    if (hide) {
      setOverflow("hidden");
      if (preventTabbing) {
        toggleEventListener(true);
      }
    } else {
      setOverflow("unset");
      if (preventTabbing) {
        toggleEventListener(false);
      }
    }

    return () => {
      setOverflow("unset");
      if (preventTabbing) {
        toggleEventListener(false);
      }
    };
  }, [hide, preventTabbing, setOverflow, toggleEventListener]);
};

export default useDisableDocumentScroll;
