import PropTypes from 'prop-types'
import Head from 'next/head'

import { withServerSideProps } from 'sholdi-hocs/withServerSideProps'

import useSettings from 'sholdi-hooks/useSettings'

import initUrql from 'sholdi-shared/lib/urql/init-urql'
import transformer from 'sholdi-shared/lib/transformers/product'
import categoryTransformer from 'sholdi-shared/lib/transformers/category'
// import faqTransfromer from 'sholdi-shared/lib/transformers/faqs'
import shopTransformer from 'sholdi-shared/lib/transformers/shop'

import { HOME_QUERY } from 'sholdi-graphql/queries/admin/homeContent'
import BASIC_PRODUCTS_QUERY from 'sholdi-graphql/queries/products/products'
import SHOPS_QUERY from 'sholdi-graphql/queries/shop/shops'

import Carousel from 'sholdi-ui/organisms/Carousel'
import Metadata from 'sholdi-primitives/atoms/Metadata'
import Faqs from 'sholdi-ui/organisms/Faqs'

import MainLayout from '../components/templates/MainLayout'
import Home from '../components/pageviews/Home'
import CATEGORIES_QUERY from 'sholdi-graphql/queries/categories/categories'
import {
  computeGridItemStyles,
  gridObjectToTailwindClasses,
} from 'sholdi-ui/molecules/GridLayout/helpers'

const IndexPage = ({ slides = [], faqs, ...props }) => {
  const { appUrl } = useSettings()
  return (
    <>
      <Head>
        <Metadata
          metaTitle="Početna"
          metaDescription="Pridružite se Sholdi platformi i olakšajte sebi posao."
          url="/"
          canonical={appUrl}
        />
        <meta httpEquiv="content-language" content="bs-BA" />
        <title>Početna | Sholdi</title>
      </Head>
      <MainLayout
        subheader={slides.length ? <Carousel slides={slides ?? []} /> : null}
        subFooter={<Faqs type="pricing" faqs={faqs} className="bg-primary-lightest" />}
      >
        <Home {...props} />
      </MainLayout>
    </>
  )
}

IndexPage.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({})),
  faqs: PropTypes.arrayOf(PropTypes.shape({})),
}

export const getServerSideProps = withServerSideProps({
  getProps: async context => {
    const { res } = context
    res.setHeader(
      'Cache-Control',
      'public, max-age=30, s-maxage=30 stale-while-revalidate=86400',
    )

    const { client } = initUrql('public', context)

    const { data, error } = await client.query(HOME_QUERY).toPromise()
    const { settingsMarketplaceSettings, sliders = [] } = data || {}

    const sections = settingsMarketplaceSettings?.[0]?.content?.variants?.default ?? []

    let pageData = {
      slides: sliders?.[0]?.sliderItems ?? [],
      sections,
    }

    for await (let section of sections) {
      if (section.type === 'gridBanner') {
        pageData = {
          ...pageData,
          [`classNames_${section.sort}`]: Array(section.banners.length)
            .fill(0)
            .map((child, index) => {
              const itemProps = computeGridItemStyles(section.layouts, index)
              const gridClasses = gridObjectToTailwindClasses(itemProps)
              return gridClasses
            }),
        }
      }
      if (section.type === 'product') {
        const { data = { products: [] } } = await client
          .query(BASIC_PRODUCTS_QUERY, {
            where: {
              id: { _in: section.banners.map(product => product.id) },
            },
          })
          .toPromise()
        pageData = {
          ...pageData,
          [`products_${section.sort}`]: data?.products.map(transformer),
        }
      }
      if (section.type === 'category') {
        const { data = { categories: [] } } = await client
          .query(CATEGORIES_QUERY, {
            where: {
              id: { _in: section.banners.map(category => category.id) },
            },
          })
          .toPromise()
        pageData = {
          ...pageData,
          [`categories_${section.sort}`]: data?.categories.map(categoryTransformer),
        }
      }
      if (section.type === 'shopCard') {
        const { data = { shops: [] } } = await client
          .query(SHOPS_QUERY, {
            where: {
              id: { _in: section.banners.map(shop => shop.id) },
            },
          })
          .toPromise()
        pageData = {
          ...pageData,
          [`shops_${section.sort}`]: data?.shops.map(shopTransformer),
        }
      }
    }

    if (!error) {
      return pageData
    }

    return {
      sections: [],
      slides: [],
    }
  },
})

export default IndexPage
