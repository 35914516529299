import PropTypes from 'prop-types'

import Container from 'sholdi-ui/molecules/Container'
import PageBanners from 'sholdi-ui/organisms/PageBanners'

const Home = props => (
  <Container>
    <PageBanners {...props} />
  </Container>
)

Home.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      sort: PropTypes.number,
    }),
  ),
}

export default Home
