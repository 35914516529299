import { memo } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const Container = ({ fluid, children, className, ...props }) => (
  <div
    // USE TAILWIND CONTAINER ?
    className={twMerge(
      "relative w-full mx-auto my-0",
      fluid
        ? "px-0 max-w-full"
        : "px-2 max-w-full md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl",
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

Container.displayName = "Container";

Container.propTypes = {
  fluid: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default memo(Container);
