import { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Box from "sholdi-primitives/atoms/Box";

const SVG = forwardRef(({ className, ...props }, ref) => (
  <Box
    as="svg"
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    // width={width}
    //height={height}
    viewBox="0 0 24 24"
    // fill={color}
    className={twMerge("w-3 h-3 fill-transparent stroke-current", className)}
    {...props}
  />
));

SVG.propTypes = {
  className: PropTypes.string,
};

SVG.displayName = "SVG";

export default memo(SVG);
