import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SkeletonBody from "sholdi-primitives/atoms/Skeleton/SkeletonBody";

const SkeletonProductList = ({
  items = 12,
  cardSkeletonClassName,
  mobile,
  className,
}) => (
  <div
    className={twMerge(
      "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2",
      className,
    )}
  >
    {!mobile &&
      Array(items)
        .fill(0)
        .map((_, i) => (
          <div className={twMerge("h-72", cardSkeletonClassName)} key={i}>
            <SkeletonBody className="h-1/2" />
            <div className="w-full h-1/2 mt-2 flex flex-col items-center">
              <SkeletonBody className="w-20 h-8 rounded mb-2 animate-none bg-gray-100" />
              <SkeletonBody className={twMerge("h-5 rounded-sm mb-2 w-32")} />
              <SkeletonBody className="w-24 h-5 rounded-sm" />
            </div>
          </div>
        ))}
  </div>
);

SkeletonProductList.propTypes = {
  items: PropTypes.number,
  className: PropTypes.string,
  cardSkeletonClassName: PropTypes.string,
  mobile: PropTypes.bool,
};

export default SkeletonProductList;
